import { useApi } from '@/hooks/useApi'
import { routerCatalog } from '@/model/routes-catalog'
import { selectProjectState, setProjectId, setProjectIsSaved } from '@/state/features/project/projectSlice'
import { selectSchemaState } from '@/state/features/schema/schemaSlice'
import { selectStructureState } from '@/state/features/structure/structureSlice'
import { useAppDispatch, useAppSelector } from '@/state/hooks'
import { SaveProjectRequestBody, SaveProjectResponseBody } from '@contember-cloud/studio-server'
import { useCallback, useRef } from 'react'
import { useParams } from 'react-router-dom'

export interface SaveProjectState {
	project: ReturnType<typeof selectProjectState>
	schemaDsl: ReturnType<typeof selectSchemaState>['dsl']['present']
	structure: {
		dsl: ReturnType<typeof selectStructureState>['dsl']['present']
	}
	org: string | undefined
}

export const useSaveProject = () => {
	const dispatch = useAppDispatch()
	const project = useAppSelector(selectProjectState)
	const { org } = useParams()
	const { dsl: schemaDsl } = useAppSelector(selectSchemaState)
	const { dsl: structureDsl } = useAppSelector(selectStructureState)

	const stateRef = useRef<SaveProjectState>({
		project,
		schemaDsl: schemaDsl.present,
		structure: { dsl: structureDsl.present },
		org,
	})

	stateRef.current = {
		project,
		schemaDsl: schemaDsl.present,
		structure: { dsl: structureDsl.present },
		org,
	}

	const [runSaveProject, saveProjectState] = useApi<SaveProjectRequestBody, SaveProjectResponseBody>(routerCatalog.apiServer.projects.save)

	const handleSaveProject = useCallback(async () => {
		if (!project.id) return null

		const result = await runSaveProject(stateRef.current)

		if (result.data.ok && result.data.result.id) {
			const { id } = result.data.result
			dispatch(setProjectId(id))
			dispatch(setProjectIsSaved(true))
			return id
		}

		return null
	}, [dispatch, project.id, runSaveProject])

	return {
		run: handleSaveProject,
		state: saveProjectState,
	}
}
