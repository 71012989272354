import { config } from '@/model/config'
import * as Sentry from '@sentry/react'
import { useEffect } from 'react'
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom'

const sentryDsn = 'https://87b74efd030747648bc173e6c2d397b7@o1050986.ingest.us.sentry.io/4504756799733760'
const sentryEnabled = config.mode !== 'development'

Sentry.init({
	enabled: sentryEnabled,
	dsn: sentryDsn,
	integrations: [
		Sentry.replayIntegration({
			maskAllText: false,
			maskAllInputs: false,
			maskAttributes: ['email', 'password'],
			blockAllMedia: false,
		}),
		Sentry.reactRouterV6BrowserTracingIntegration({
			useEffect,
			useLocation,
			useNavigationType,
			createRoutesFromChildren,
			matchRoutes,
		}),
	],

	tracesSampleRate: 1.0,
	tracePropagationTargets: [
		/^https:\/\/studio-server\.contember\.com/,
		/^https:\/\/studio-server\.stage\.contember\.com/,
		/^http:\/\/studio\.server\.contember\.local/,
	],

	replaysSessionSampleRate: 0,
	replaysOnErrorSampleRate: 1.0,
})
