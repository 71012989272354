import { LoginBackground } from '@/components/login'
import { ContemberHorizontal } from '@/components/logos'
import { OrganizationSelect } from '@/components/organization'
import { MeResponseOrganization, cls } from '@contember-cloud/studio-server'
import { Person } from '@contember-cloud/studio-server'
import React, { ReactNode } from 'react'
import { Helmet as Head } from 'react-helmet-async'

export interface LayoutBaseProps {
	title?: string
	children: ReactNode
}

interface LayoutProps extends LayoutBaseProps {
	sectionName?: ReactNode
	action?: ReactNode
	person?: Person
	organizations?: MeResponseOrganization[]
}

const LayoutHeader = ({ organizations }: { organizations?: MeResponseOrganization[] }) => {
	return (
		<header className={'flex justify-between items-center py-2 pl-2 pr-4 bg-neutral-900 text-white'}>
			<div className={'flex items-center'}>
				<a href="/">
					<ContemberHorizontal className="h-14 w-auto" />
				</a>

				{organizations && organizations?.length > 1 ? (
					<OrganizationSelect organizations={organizations} />
				) : (
					organizations?.map(org => (
						<a key={org.slug} href={`/${org.slug}/projects`}>
							{org.name}
						</a>
					))
				)}
			</div>

			<a href="/logout" className={cls('no-underline', 'hover:underline')}>
				Logout
			</a>
		</header>
	)
}

export const Layout = ({ children, title, sectionName, action, organizations }: LayoutProps) => {
	return (
		<>
			<Head>
				<title>{title ?? 'AI-assisted Contember Studio'}</title>
			</Head>

			<LayoutHeader organizations={organizations} />

			<div className="min-h-screen flex flex-col overflow-hidden pt-10 sm:pt-18 bg-neutral-100">
				<main className="max-w-4xl w-full h-full px-5 pb-0 flex flex-grow flex-col sm:flex-row gap-4 items-stretch mx-auto relative">
					<section className="flex flex-col flex-grow">
						<header className="flex flex-col sm:flex-row justify-between items-stretch sm:items-center mb-0 py-5 md:py-8 gap-y-6 sm:gap-y-0">
							<h2 className="font-wallop text-4xl font-semibold mb-2">{sectionName}</h2>
							<div>{action}</div>
						</header>
						{children}
					</section>
				</main>
			</div>
		</>
	)
}

export const WideLayout = ({ children, title }: LayoutBaseProps) => {
	return (
		<>
			<Head>
				<title>{title ?? 'AI-assisted Contember Studio'}</title>
			</Head>

			<LayoutHeader organizations={undefined} />

			<div className="w-full min-h-screen bg-neutral-100">
				<main className="w-full px-2 sm:px-5 pb-0 items-stretch mx-auto relative min-h-screen">
					<section className="flex flex-col flex-grow">{children}</section>
				</main>
			</div>
		</>
	)
}

export const LayoutCenter = ({ children, title }: LayoutBaseProps) => {
	return (
		<>
			<Head>
				<title>{title ?? 'AI-assisted Contember Studio'}</title>
			</Head>
			<header className="fixed w-full flex justify-between items-center pr-4 bg-neutral-900 text-white">
				<a href="/">
					<ContemberHorizontal className="h-14 w-auto" />
				</a>
				<a href="/logout" className={cls('no-underline', 'hover:underline')}>
					Logout
				</a>
			</header>
			<div className="w-full min-h-screen flex items-center py-5 sm:py-10 bg-neutral-100">
				<main className="w-full max-w-4xl px-2 sm:px-5 pb-0 items-stretch mx-auto relative">
					<section className="flex flex-col flex-grow">{children}</section>
				</main>
			</div>
		</>
	)
}

export const LayoutAuth = ({ children, title }: LayoutBaseProps) => {
	return (
		<>
			<Head>
				<title>{title ?? 'AI-assisted Contember Studio'}</title>
			</Head>
			<header className="absolute l-0 t-0 w-full py-2 pl-2 pr-4 text-white sm:text-neutral-900">
				<a href="/">
					<ContemberHorizontal className="h-14 w-auto"/>
				</a>
			</header>
			<div className="sm:flex h-screen text-white sm:text-neutral-900">
				<div className="w-full flex-0 sm:w-1/2 px-4">
					<div className="flex items-center justify-center h-screen flex-col gap-12">{children}</div>
				</div>
				<div className="fixed top-0 left-0 w-full flex-0 -z-10 sm:w-1/2 h-screen overflow-hidden sm:relative">
					<LoginBackground className="min-w-full min-h-full w-auto h-auto absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2" />
				</div>
			</div>
		</>
	)
}
