import { selectSchema } from '@/state/features/schema/schemaSlice'
import { buildStructureFromDsl, emptySchemaExtensions, printCrudStructureStmts, printStructureStmts } from '@contember-cloud/studio-dsl'
import { StructureSliceState, structureAiAssistantPrefix } from '@contember-cloud/studio-server'
import { emptySchema } from '@contember/schema-utils'
import { PayloadAction, ThunkAction, UnknownAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { z } from 'zod'
import { RootState } from '../../store'
import { resetProject } from '../project/projectSlice'

export const emptyStructureDsl = {
	past: [],
	present: '',
	future: [],
}

const initialState: StructureSliceState = {
	dsl: emptyStructureDsl,
}

export const structureSlice = createSlice({
	name: 'structure',
	initialState,
	reducers: {
		setStructure(state, action: PayloadAction<StructureSliceState>) {
			return action.payload
		},
		setIsStructureGenerating(state, action: PayloadAction<boolean>) {
			state.isStructureGenerating = action.payload
		},
		setStructureVisualizer(state, action: PayloadAction<StructureSliceState['structureVisualizer']>) {
			state.structureVisualizer = action.payload
		},
		setStructureAIAssistantPrefix(state, action: PayloadAction<z.infer<typeof structureAiAssistantPrefix>>) {
			state.aiAssistant = {
				...state.aiAssistant,
				prefix: action.payload,
			}
		},
		setStructureCrudDsl(state, action: PayloadAction<string>) {
			state.crudDsl = action.payload
		},
		resetStructureAIAssistantPrefix(state) {
			state.aiAssistant = {
				...state.aiAssistant,
				prefix: undefined,
			}
		},
		addStmtToStructureDsl(state, action: PayloadAction<string>) {
			state.dsl = {
				...state.dsl,
				past: [...(state.dsl.past ?? []), state.dsl.present],
				present: state.dsl.present + `\n${action.payload}`,
			}
		},
		setPendingStructureDsl(state, action: PayloadAction<string>) {
			state.pendingDsl = action.payload
		},
		addStmtToPendingStructureDsl(state, action: PayloadAction<string>) {
			state.pendingDsl = (state.pendingDsl ?? '') + `\n${action.payload}`
		},
		resetPendingStructureDsl(state) {
			state.pendingDsl = undefined
		},
		removeStmtFromPendingStructureDsl(state, action: PayloadAction<string>) {
			state.pendingDsl = state.pendingDsl?.replace(action.payload, '')
		},
		stepBackInStructureDsl(state) {
			if (state.dsl.past.length === 0) return

			state.dsl = {
				...state.dsl,
				future: [state.dsl.present, ...(state.dsl.future ?? [])],
				present: state.dsl.past[state.dsl.past.length - 1],
				past: state.dsl.past.slice(0, state.dsl.past.length - 1),
			}
		},
		stepForwardInStructureDsl(state) {
			if (state.dsl.future.length === 0) return

			state.dsl = {
				...state.dsl,
				past: [...(state.dsl.past ?? []), state.dsl.present],
				present: state.dsl.future[0],
				future: state.dsl.future.slice(1),
			}
		},
		setShowMenuPanel(state, action: PayloadAction<boolean>) {
			state.showMenuPanel = action.payload
		},
	},
	extraReducers: builder => {
		builder.addCase(setStructureDsl.fulfilled, (state, action) => {
			state.crudDsl = action.payload.crudDsl
			state.dsl = {
				...state.dsl,
				past: [...(state.dsl.past ?? []), state.dsl.present],
				present: action.payload.dsl,
			}
			state.cleanedDsl = action.payload.cleanedDsl
		})
		builder.addCase(resetProject, () => {
			return initialState
		})
	},
})

export const addStmtToStructureDslAndEmit =
	(stmt: string): ThunkAction<void, RootState, any, UnknownAction> =>
	(dispatch, getState, { socket }) => {
		dispatch({ type: 'structure/addStmtToStructureDsl', payload: stmt })

		socket.emit('addStmtToStructureDsl', { stmt, projectId: getState().project.id })
	}

export const {
	setStructure,
	setIsStructureGenerating,
	setStructureVisualizer,
	setStructureAIAssistantPrefix,
	resetStructureAIAssistantPrefix,
	setPendingStructureDsl,
	resetPendingStructureDsl,
	removeStmtFromPendingStructureDsl,
	addStmtToPendingStructureDsl,
	addStmtToStructureDsl,
	setStructureCrudDsl,
	stepBackInStructureDsl,
	stepForwardInStructureDsl,
	setShowMenuPanel,
} = structureSlice.actions

export const setStructureDsl = createAsyncThunk('structure/setStructureModel', async (dsl: string, { getState }) => {
	const state = getState() as RootState
	const project = state.project
	const { contemberSchema, schemaExtensions } = selectSchema(state) ?? { contemberSchema: emptySchema, schemaExtensions: emptySchemaExtensions }
	const crudDsl = printCrudStructureStmts(contemberSchema, schemaExtensions)
	const structureResult = buildStructureFromDsl(dsl, contemberSchema, schemaExtensions)
	const cleanedDsl = printStructureStmts(structureResult.structure)

	return { ...structureResult, ...project, dsl, crudDsl, cleanedDsl }
})

export const selectStructureState = (state: RootState) => state.structure
